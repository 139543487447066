import { Stack } from '@mui/material';
import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';
import { ReactNode, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { LoadingIndicator } from '../loading/LoadingIndicator';

export interface DataTableColumn {
  field: string;
  headerName: string;
  maxWidth?: number;
  minWidth?: number;
  sortable?: boolean;
  flex?: number;
  type?: ColumnType;
  renderCell?: (param: any) => ReactNode;
}

export interface SortDef {
  field: string;
  sort: 'asc' | 'desc';
}
export enum ColumnType {
  List = 'list',
  Date = 'date',
}
export interface DataTableRow {
  id: string;
}

interface DataTableProps<T extends DataTableRow> {
  id: string;
  rows: T[];
  columns: DataTableColumn[];
  loading?: boolean;
  actionButtonsFactoryMethod?: (rowId: string) => ReactNode;
  initialPageSize?: number;
  sort?: SortDef[];
  onRowClick?: (rowId: string) => void;
  onMultiRowSelect?: (rowIds: string[]) => ReactNode;
  showToolBar?: boolean;
  showExportButton?: boolean;
  pageSizeOptions?: number[];
  rowHeight?: 'auto' | number;
}
const defaultPageSizeOptions = [5, 10, 25, 100, 200, 1000, 5000];

export function DataTable<T extends DataTableRow>({
  id,
  rows,
  columns,
  loading,
  actionButtonsFactoryMethod,
  initialPageSize = 25,
  sort,
  onRowClick,
  onMultiRowSelect,
  showToolBar = true,
  showExportButton = true,
  pageSizeOptions = defaultPageSizeOptions,
  rowHeight = 'auto',
}: DataTableProps<T>) {
  const intl = useIntl();
  const { rowData, columnData } = mapToMaterialUIRowsAndColumns({
    rows,
    columns,
    intl,
    actionButtonsFactoryMethod,
  });
  function handleRowClick(params: GridCellParams) {
    if (onRowClick && params.field !== 'action') {
      onRowClick(params.id.toString());
    }
  }
  function handleRowSelection(rows: GridRowSelectionModel) {
    if (onMultiRowSelect) onMultiRowSelect(rows.map(r => r.toString()));
  }
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const filter = query.get('filter');

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const columnVisibilityModelKey = `dataTable_${id}_columnVisibilityModel`;
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const sortModelKey = `dataTable_${id}_sortModel`;
  const [localStorageLoading, setLocalStorageLoading] = useState(true);
  useEffect(() => {
    const columnVisibilityModelStringFromStorage = localStorage.getItem(columnVisibilityModelKey);
    if (columnVisibilityModelStringFromStorage) {
      const columnVisibilityModelFromStorage = JSON.parse(columnVisibilityModelStringFromStorage);
      setColumnVisibilityModel(columnVisibilityModelFromStorage);
    }
    const sortModelStringFromStorage = localStorage.getItem(sortModelKey);
    if (sortModelStringFromStorage) {
      const sortModelFromStorage = JSON.parse(sortModelStringFromStorage);
      setSortModel(sortModelFromStorage);
    }
    setLocalStorageLoading(false);
  }, []);

  return (
    <>
      {(loading || localStorageLoading) && <LoadingIndicator />}
      {!loading && !localStorageLoading && (
        <DataGridPremium
          autosizeOnMount
          pagination
          pageSizeOptions={pageSizeOptions}
          initialState={{
            pagination: {
              paginationModel: { pageSize: initialPageSize, page: 0 },
            },
            sorting: {
              sortModel: sort,
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterValues: [filter],
              },
            },
          }}
          onCellClick={handleRowClick}
          rows={rowData}
          columns={columnData}
          sx={getDataTableStyle()}
          disableColumnReorder
          disableRowSelectionOnClick
          disableColumnMenu
          disableDensitySelector
          getRowHeight={() => rowHeight}
          autoHeight={true}
          slots={showToolBar ? { toolbar: CustomToolbar } : {}}
          checkboxSelection={!!onMultiRowSelect}
          checkboxSelectionVisibleOnly
          onRowSelectionModelChange={handleRowSelection}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={model => {
            setColumnVisibilityModel(model);
            localStorage.setItem(columnVisibilityModelKey, JSON.stringify(model));
          }}
          sortModel={sortModel}
          onSortModelChange={model => {
            setSortModel(model);
            localStorage.setItem(sortModelKey, JSON.stringify(model));
          }}
        />
      )}
    </>
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarQuickFilter input={''} style={{ marginLeft: 'auto' }} />
        {showExportButton && <GridToolbarExport />}
      </GridToolbarContainer>
    );
  }
}

interface MapRowsAndColumnsParams {
  rows: any[];
  columns: DataTableColumn[];
  intl: IntlShape;
  actionButtonsFactoryMethod?: (rowId: string) => ReactNode;
}
function mapToMaterialUIRowsAndColumns({
  rows,
  columns,
  intl,
  actionButtonsFactoryMethod,
}: MapRowsAndColumnsParams): {
  rowData: GridRowsProp;
  columnData: GridColDef[];
} {
  const rowData: GridRowsProp = rows.map(row => ({ ...row }));
  const columnData: GridColDef[] = columns.map(column => mapColumn(column));
  function getActionButtons(rowId: string) {
    return actionButtonsFactoryMethod ? actionButtonsFactoryMethod(rowId) : <></>;
  }
  if (actionButtonsFactoryMethod) {
    columnData.push(actionColumnDefinition({ getActionButtons, intl }));
  }
  return { rowData, columnData };
}

function mapColumn(column: DataTableColumn): GridColDef {
  const muiColumn: GridColDef = { ...column, flex: 1, sortable: true };
  if (column.type === ColumnType.List) {
    return {
      ...muiColumn,
      renderCell: param => ListRow(param.value, column.maxWidth ?? 320),
    };
  }
  if (column.type == ColumnType.Date) {
    return {
      ...muiColumn,
      renderCell: param => formatDate(param.value),
    };
  }
  return muiColumn;
}
function formatDate(value: Date) {
  return value.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
}
function ListRow(values: string[], maxWidth: number) {
  if (!values) {
    return <></>;
  }
  return (
    <p
      style={{ paddingTop: '10px', paddingBottom: '10px', maxWidth: maxWidth, overflow: 'hidden' }}
    >
      {values.join(', ')}
    </p>
  );
}
interface ActionColumnDefinitionParams {
  getActionButtons: (rowId: string) => ReactNode;
  intl: IntlShape;
}
function actionColumnDefinition({
  getActionButtons,
  intl,
}: ActionColumnDefinitionParams): GridColDef {
  return {
    field: 'action',
    headerName: intl.formatMessage({ id: 'general.actions' }),
    sortable: false,
    flex: 1,
    renderCell: params => {
      return (
        <>
          <Stack direction="row">{getActionButtons(params.row.id)}</Stack>
        </>
      );
    },
  };
}

function getDataTableStyle() {
  return {
    bgcolor: 'white',
  };
}
