import { createContext, ReactNode, useContext } from 'react';

import { useStock } from '../../../api/hooks/useStockApi';
import { Stock } from '../../../types/types';

interface StockPageContextApi {
  stock: Stock[];
  isLoading: boolean;
}

const StockPageContext = createContext<StockPageContextApi | undefined>(undefined);

interface StockPageProviderProps {
  children: ReactNode;
}

function StockPageProvider({ children }: StockPageProviderProps) {
  const { isLoading, itemsStock } = useStock();

  const value: StockPageContextApi = {
    stock: itemsStock,
    isLoading,
  };
  return <StockPageContext.Provider value={value}>{children}</StockPageContext.Provider>;
}

function useStockPageContext() {
  const context = useContext(StockPageContext);
  if (context === undefined) {
    throw new Error('useStockPageContext must be used within an StockPageProvider');
  }
  return context;
}

export { StockPageProvider, useStockPageContext };
