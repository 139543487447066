import { SimpleNotification } from '../../components/common/notification/SimpleNotification';
import { StockPageProvider } from '../../components/stock/context/StockPageProvider';
import { StockPageHeader } from '../../components/stock/StockPageHeader';
import { StockTable } from '../../components/stock/StockTable';
import { NotificationMessageProvider } from '../../context/notificationMessageContext';

function StockPage() {
  return (
    <NotificationMessageProvider>
      <StockPageProvider>
        <StockPageHeader />
        <StockTable />
      </StockPageProvider>
      <SimpleNotification />
    </NotificationMessageProvider>
  );
}

export { StockPage };
