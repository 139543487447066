import { useQuery } from 'react-query';

import { useClient } from '../../context/auth/UseClient';
import { Stock } from '../../types/types';

const STOCK_ROUTE = 'items/stock';

function useStock() {
  const client = useClient<Stock[]>();

  const result = useQuery('itemsStock', () => client(STOCK_ROUTE));
  return { ...result, itemsStock: result.data?.data ?? [] };
}

export { useStock };
