import { useIntl } from 'react-intl';

import { StockRow, StockRowFieldName } from '../../types/StockRow';
import { Stock } from '../../types/types';
import { DataTable, DataTableColumn } from '../library/table/DataTable';

import { useStockPageContext } from './context/StockPageProvider';

export function StockTable() {
  const intl = useIntl();
  const { stock, isLoading } = useStockPageContext();

  return (
    <DataTable
      id="StockTable"
      rows={createRows()}
      columns={createStockColumns()}
      loading={isLoading}
    />
  );

  function createRows(): StockRow[] {
    return stock.map((stock: Stock): StockRow => {
      return {
        id: `${stock.itemType.id}-${stock.station.id}`,
        itemType: stock.itemType.name,
        station: stock.station.name,
        rented: stock.itemType.isConsumable ? '-' : stock.stock?.rented || 0,
        overdue: stock.itemType.isConsumable ? '-' : stock.stock?.overdue || 0,
        total: stock.stock?.available || 0,
        requiresService: stock.stock?.requiresService || 0,
      };
    });
  }

  function createStockColumns() {
    const stockColumns: DataTableColumn[] = [
      {
        field: StockRowFieldName.ItemType,
        headerName: intl.formatMessage({ id: 'stockPage.itemType' }),
      },
      {
        field: StockRowFieldName.Station,
        headerName: intl.formatMessage({ id: 'stockPage.stationLocation' }),
      },
      {
        field: StockRowFieldName.Rented,
        headerName: intl.formatMessage({ id: 'stockPage.rented' }),
      },
      {
        field: StockRowFieldName.Overdue,
        headerName: intl.formatMessage({ id: 'stockPage.overdue' }),
      },
      {
        field: StockRowFieldName.Total,
        headerName: intl.formatMessage({ id: 'stockPage.available' }),
      },
      {
        field: StockRowFieldName.RequiresService,
        headerName: intl.formatMessage({ id: 'stockPage.requiredService' }),
      },
    ];
    return stockColumns;
  }
}
