import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { PageHeader } from '../common/PageHeader';

export interface StockPageHeaderProps {
  children?: ReactNode;
}

function StockPageHeader({ children }: StockPageHeaderProps) {
  const intl = useIntl();
  return <PageHeader breadCrumbs={stockPageBreadCrumbs(intl)}>{children}</PageHeader>;
}
function stockPageBreadCrumbs(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '/systems',
    },
    {
      label: intl.formatMessage({ id: 'stockPage.self' }),
      route: '/stock',
    },
  ];
}
export { StockPageHeader };
