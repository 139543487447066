import { useOktaAuth } from "@okta/okta-react";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

type AuthenticatedQueryOptions<TData, TError> = UseQueryOptions<TData, TError> & {
  enabled?: boolean;
};

export function useAuthenticatedQuery<TData = unknown, TError = unknown>(
  queryKey: string | unknown[],
  queryFn: () => Promise<TData>,
  options?: AuthenticatedQueryOptions<TData, TError>
): UseQueryResult<TData, TError> {
  const { authState } = useOktaAuth();
  const isEnabled = !!authState && authState.isAuthenticated && (options?.enabled ?? true);
  return useQuery<TData, TError>(queryKey, queryFn, {
    ...options,
    retry: 3,
    retryDelay: 500,
    enabled: isEnabled,
  });
}