import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useSystems } from '../../api/hooks/useSystemsApi';
import { OptionItem, UserGlobalRole, UserRole } from '../../types/types';

interface CreateUserDialogProps {
  toggleIsCreatingUser: React.Dispatch<React.SetStateAction<boolean>>;
  isCreatingUser: boolean;
  onSubmit: (data: CreateUserFormData) => Promise<void>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface UserRoleInfo {
  _rrSystemId: string;
  role: UserRole;
}

export interface CreateUserFormData {
  email: string;
  rrSystemOptions?: OptionItem[];
  roles?: UserRoleInfo[];
  globalRole: UserGlobalRole[];
}

function CreateUserDialog({
  toggleIsCreatingUser,
  isCreatingUser,
  onSubmit,
}: CreateUserDialogProps) {
  const intl = useIntl();
  const { systems } = useSystems();

  const [rrSystemName, setRRSystem] = React.useState<string[]>([]);
  const [rrSystemIdToClient, setRRSystemIdToClient] = React.useState<Record<string, string>>({});
  const [globalRole, setGlobalRole] = React.useState<string>('None');

  const handleChange = (event: SelectChangeEvent<typeof rrSystemName>) => {
    const {
      target: { value },
    } = event;
    setRRSystem(typeof value === 'string' ? value.split(',') : value);
  };

  const handleGlobalRoleChange = (event: SelectChangeEvent) => {
    setGlobalRole(event.target.value);
  };

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitSuccessful },
    control,
    reset,
  } = useForm<CreateUserFormData>({
    mode: 'onChange',
  });

  useEffect(() => {
    setRRSystemIdToClient(
      systems.reduce((acc: Record<string, string>, rrSystem) => {
        acc[rrSystem._id] = rrSystem.client;
        return acc;
      }, {})
    );
    if (isSubmitSuccessful) {
      reset({
        email: '',
        rrSystemOptions: [],
        globalRole: undefined,
        roles: [],
      });
    }
  }, [isSubmitSuccessful, systems]);

  return (
    <Dialog id={'CreateUserDialog'} open={isCreatingUser} keepMounted>
      <DialogTitle>{intl.formatMessage({ id: 'users.create' })}</DialogTitle>
      <DialogContent>
        <Grid minWidth={'500px'}>
          <form
            id={'createUserForm'}
            noValidate
            autoComplete={'false'}
            onSubmit={handleSubmit(data => {
              delete data.rrSystemOptions;
              onSubmit(data);
            })}
          >
            <TextField
              sx={{ my: 1 }}
              required={true}
              fullWidth={true}
              id="userEmail"
              label={intl.formatMessage({ id: 'general.email' })}
              variant="outlined"
              autoComplete={'off'}
              type={'email'}
              {...register('email', {
                required: true,
              })}
            />
            <FormControl fullWidth sx={{ my: 1 }}>
              <InputLabel required={true} id="userGlobalRole">
                {intl.formatMessage({ id: 'users.globalRole' })}
              </InputLabel>
              <Select
                labelId="userGlobalRole"
                id="userGlobalRoleSelect"
                value={globalRole}
                required={true}
                label={intl.formatMessage({ id: 'users.globalRole' })}
                {...register('globalRole', {
                  required: true,
                  onChange: e => handleGlobalRoleChange(e),
                })}
              >
                {Object.entries(UserGlobalRole).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth={true} sx={{ my: 1 }}>
              <InputLabel sx={{ bgcolor: 'white' }} id="userRrSystem">
                {intl.formatMessage({ id: 'users.addToRrSystem' })}
              </InputLabel>
              <Select
                labelId="userRrSystem"
                label={intl.formatMessage({ id: 'users.addToRrSystem' })}
                id="userRrSystemSelect"
                multiple
                value={rrSystemName}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={selected => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map(value => (
                      <Chip key={value} id={'rrSystem' + value} label={rrSystemIdToClient[value]} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                {...register('rrSystemOptions', {
                  onChange: e => handleChange(e),
                })}
              >
                {systems.map(rrSystem => (
                  <MenuItem
                    id={'menuItem' + rrSystem.client}
                    key={rrSystem._id}
                    value={rrSystem._id}
                  >
                    {rrSystem.client}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {rrSystemName.length > 0 && (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>System</TableCell>
                      <TableCell>Berechtigung</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rrSystemName.map((system, index) => (
                      <TableRow key={system}>
                        <TableCell>{rrSystemIdToClient[system]}</TableCell>
                        <TableCell>
                          <Controller
                            name={`roles[${index}].role` as `roles.${number}.role`}
                            control={control}
                            rules={{ required: true }}
                            defaultValue={UserRole.READONLY}
                            render={({ field }) => (
                              <Select id={'rrSystemRoleSelect' + system} {...field}>
                                {Object.values(UserRole).map(role => (
                                  <MenuItem
                                    id={'menuItemRrsystemRole' + role}
                                    key={role}
                                    value={role}
                                  >
                                    {role}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          <input
                            type="hidden"
                            {...register(
                              `roles[${index}]._rrSystemId` as `roles.${number}._rrSystemId`
                            )}
                            value={system}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </form>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          id={'closeBtn'}
          onClick={() => {
            toggleIsCreatingUser(false);
            setGlobalRole('');
            setRRSystem([]);
            reset({
              email: '',
              rrSystemOptions: undefined,
              globalRole: undefined,
              roles: undefined,
            });
          }}
        >
          {intl.formatMessage({ id: 'general.cancel' })}
        </Button>
        <Button
          form="createUserForm"
          id={'confirmBtn'}
          type={'submit'}
          variant={'contained'}
          color={'primary'}
          disabled={!isValid}
        >
          {intl.formatMessage({ id: 'general.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { CreateUserDialog };
