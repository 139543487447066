import { ReactNode, createContext, useContext } from 'react';

import { useGetAlertRules } from '../api/hooks/useAlertRulesApi';
import { useItems } from '../api/hooks/useItemApi';
import { useRenters } from '../api/hooks/useRentingApi';
import { useRentalHistory } from '../api/hooks/useReportsApi';
import { useStations } from '../api/hooks/useStationApi';
import { useStock } from '../api/hooks/useStockApi';
import { AlertRule } from '../types/alert-rules/AlertRule';
import { Renter } from '../types/renter/Renter';
import { Item, RentalHistoryEntry, Station, Stock } from '../types/types';

interface DashboardContextApi {
  rentalHistory: RentalHistoryEntry[];
  rentalHistoryIsLoading: boolean;
  renters: Renter[];
  rentersIsLoading: boolean;
  stations: Station[];
  stationsIsLoading: boolean;
  items: Item[];
  itemsIsLoading: boolean;
  itemsStock: Stock[];
  itemsStockIsLoading: boolean;
  alertRules: AlertRule[];
  alertRulesAreLoading: boolean;
}

const DashboardContext = createContext<DashboardContextApi | undefined>(undefined);

interface DashboardProviderProps {
  children: ReactNode;
}

function DashboardProvider({ children }: DashboardProviderProps) {
  const { rentalHistory, isLoading: rentalHistoryIsLoading } = useRentalHistory();
  const { renters, isLoading: rentersIsLoading } = useRenters();
  const { stations, isLoading: stationsIsLoading } = useStations();
  const { items, isLoading: itemsIsLoading } = useItems();
  const { itemsStock, isLoading: itemsStockIsLoading } = useStock();
  const { alertRules, isLoading: alertRulesAreLoading } = useGetAlertRules();

  const value: DashboardContextApi = {
    rentalHistory,
    rentalHistoryIsLoading,
    renters,
    rentersIsLoading,
    stations,
    stationsIsLoading,
    items,
    itemsIsLoading,
    itemsStock,
    itemsStockIsLoading,
    alertRules,
    alertRulesAreLoading,
  };
  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
}

function useDashboardContext() {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboardContext must be used within an DashboardProvider');
  }
  return context;
}

export { DashboardProvider, useDashboardContext };
