import { IntlShape, useIntl } from 'react-intl';

import { useContractors } from '../../api/hooks/useContractorApi';
import {
  QUANTITY_RESTRICTIONS_QUERY_KEY,
  useGetQuantityRestrictions,
} from '../../api/hooks/useQuantityRestrictionsApi';
import { useItemTypes, useRenters } from '../../api/hooks/useRentingApi';
import { Contractor, getContractorNames } from '../../types/contractors/Contractor';
import { getItemTypeNames, ItemType } from '../../types/item-types/ItemType';
import { QuantityRestriction } from '../../types/quantity-restrictions/QuantityRestriction';
import {
  QuantityRestrictionRow,
  QuantityRestrictionRowFieldName,
} from '../../types/quantity-restrictions/QuantityRestrictionRow';
import { findRenterNames, Renter } from '../../types/renter/Renter';
import { entityDeleteButtonFactoryMethod } from '../common/crud/DeleteEntityButton';
import { entityEditButtonFactoryMethod } from '../common/crud/EditEntityButton';
import { ColumnType, DataTable, DataTableColumn } from '../library/table/DataTable';

export function QuantityRestrictionsTable() {
  const { quantityRestrictions, isLoading: quantityRestrictionsLoading } =
    useGetQuantityRestrictions();
  const { itemTypes, isLoading: itemTypesLoading } = useItemTypes();
  const { contractors, isLoading: contractorsLoading } = useContractors();
  const { renters, isLoading: rentersLoading } = useRenters();
  const isLoading =
    quantityRestrictionsLoading || itemTypesLoading || contractorsLoading || rentersLoading;
  const intl = useIntl();
  const alertRuleColumns = createAlertRuleColumns(intl);
  const alertRuleRows = createRows(quantityRestrictions, intl, itemTypes, contractors, renters);
  function actionButtonsFactorymethod(id: string) {
    return buttonFactory(quantityRestrictions, id);
  }
  return (
    <DataTable
      id="QuantityRestrictionsTable"
      rows={alertRuleRows}
      columns={alertRuleColumns}
      actionButtonsFactoryMethod={actionButtonsFactorymethod}
      loading={isLoading}
    />
  );
}
function createRows(
  quantityRestrictions: QuantityRestriction[],
  intl: IntlShape,
  itemTypes: ItemType[],
  contractors: Contractor[],
  renters: Renter[]
): QuantityRestrictionRow[] {
  return quantityRestrictions.map(
    (quantityRestriction: QuantityRestriction): QuantityRestrictionRow => {
      return {
        id: quantityRestriction.id,
        itemTypes: quantityRestriction.itemTypeIds
          ? getItemTypeNames(quantityRestriction.itemTypeIds, itemTypes)
          : [],
        renters: quantityRestriction.renterIds
          ? findRenterNames(quantityRestriction.renterIds, renters)
          : [],
        contractors: quantityRestriction.contractorIds
          ? getContractorNames(quantityRestriction.contractorIds, contractors)
          : [],
        quantity: quantityRestriction.quantity,
      };
    }
  );
}
function createAlertRuleColumns(intl: IntlShape) {
  const alertRuleColumns: DataTableColumn[] = [
    {
      field: QuantityRestrictionRowFieldName.Quantity,
      headerName: intl.formatMessage({ id: 'quantity-restrictions.quantity' }),
    },
    {
      field: QuantityRestrictionRowFieldName.ItemTypes,
      headerName: intl.formatMessage({ id: 'item-types.self' }),
      type: ColumnType.List,
    },
    {
      field: QuantityRestrictionRowFieldName.Renters,
      headerName: intl.formatMessage({ id: 'renters.self' }),
      type: ColumnType.List,
    },
    {
      field: QuantityRestrictionRowFieldName.Contractors,
      headerName: intl.formatMessage({ id: 'contractors.self' }),
      type: ColumnType.List,
    },
  ];
  return alertRuleColumns;
}

function buttonFactory(quantityRestrictions: QuantityRestriction[], id: string) {
  const qr = quantityRestrictions.find(qr => qr.id == id);
  return (
    <>
      {entityEditButtonFactoryMethod(qr?.id ?? '', qr)}
      {deleteButtonFactoryMethod(qr)}
    </>
  );
}
function deleteButtonFactoryMethod(qr: QuantityRestriction | undefined) {
  return entityDeleteButtonFactoryMethod(
    qr?.id ?? '',
    'quantity-restrictions',
    QUANTITY_RESTRICTIONS_QUERY_KEY
  );
}
