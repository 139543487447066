import { useIntl } from 'react-intl';

import { RENTERS_QUERY_KEY, RENTERS_URL } from '../../api/hooks/useRentingApi';
import { useCrudPageUiStateContext } from '../../context/CrudPageUiContext';
import { Renter } from '../../types/renter/Renter';
import { RenterRow, RenterRowFieldName } from '../../types/renter/RenterRow';
import { entityDeleteButtonFactoryMethod } from '../common/crud/DeleteEntityButton';
import { detailViewButtonFactoryMethod } from '../common/crud/DetailViewButton';
import { entityEditButtonFactoryMethod } from '../common/crud/EditEntityButton';
import { Icon } from '../library/icons/Icon';
import { ColumnType, DataTable, DataTableColumn } from '../library/table/DataTable';

import { useRentersPageContext } from './contexts/RentersPageContext';

export function RentersTable() {
  const intl = useIntl();
  const { setEntitieIdsToDelete } = useCrudPageUiStateContext<Renter>();
  const { renters, rentersIsLoading } = useRentersPageContext();

  return (
    <DataTable
      id="RentersTable"
      rows={createRows()}
      columns={createRenterColumns()}
      actionButtonsFactoryMethod={actionButtonsFactoryMethod}
      onMultiRowSelect={setEntitieIdsToDelete}
      loading={rentersIsLoading}
    />
  );

  function actionButtonsFactoryMethod(renterId: string) {
    const renter = renters.find(r => r._id == renterId);
    return (
      <>
        {entityEditButtonFactoryMethod(renter?._id ?? '', renter)}
        {detailViewButtonFactoryMethod(renter)}
        {entityDeleteButtonFactoryMethod(renter?._id ?? '', RENTERS_URL, RENTERS_QUERY_KEY)}
      </>
    );
  }
  function createRows(): RenterRow[] {
    return renters.map((renter: Renter): RenterRow => {
      return {
        id: renter._id,
        fullName: renter.firstName + ' ' + renter.lastName,
        barcode: renter.barcode,
        contractor: renter.contractor.name,
        supervisorEmails: renter.supervisorEmails,
        qualificationNames: renter.qualificationNames,
        isRenterAdmin: renter.isRenterAdmin,
        status: translateRenterStatus(renter.status),
      };
    });
  }
  function translateRenterStatus(renterStatus: string): string {
    return intl.formatMessage({ id: `renters.status${renterStatus}` });
  }

  function createRenterColumns() {
    const renterColumns: DataTableColumn[] = [
      {
        field: RenterRowFieldName.FullName,
        headerName: intl.formatMessage({ id: 'renters.fullName' }),
      },
      {
        field: RenterRowFieldName.Barcode,
        headerName: intl.formatMessage({ id: 'renters.eId' }),
      },
      {
        field: RenterRowFieldName.Contractor,
        headerName: intl.formatMessage({ id: 'contractors.singular' }),
      },
      {
        field: RenterRowFieldName.SupervisorEmails,
        headerName: intl.formatMessage({ id: 'renters.supervisors' }),
        type: ColumnType.List,
      },
      {
        field: RenterRowFieldName.QualificationNames,
        headerName: intl.formatMessage({ id: 'renters.qualifications' }),
        type: ColumnType.List,
      },
      {
        field: RenterRowFieldName.IsRenterAdmin,
        headerName: intl.formatMessage({ id: 'renters.isRenterAdmin' }),
        renderCell: param => {
          return <RenterIsAdminIcon value={param.value} />;
        },
      },
      {
        field: RenterRowFieldName.Status,
        headerName: intl.formatMessage({ id: 'renters.status' }),
      },
    ];
    return renterColumns;
  }
}
interface RenterIsAdminIconProps {
  value: any;
}
function RenterIsAdminIcon({ value }: RenterIsAdminIconProps) {
  if (value) return <Icon iconName={'check'} additionalClasses={'odx-blue-text'} />;
  return <Icon iconName={'blocked'} />;
}
