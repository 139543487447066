import { useIntl } from 'react-intl';

import { MenuItem } from '../components/app/BurgerMenu';
import { ContractorsPage } from '../modules/renting/contractors/ContractorsPage';
import { ItemTypesPage } from '../modules/renting/item-types/ItemTypesPage';
import { ItemsPage } from '../modules/renting/items/ItemsPage';
import { QualificationsPage } from '../modules/renting/qualifications/QualificationsPage';
import { ReportsPage } from '../modules/reports/ReportsPage';
import { ServiceTypesPage } from '../modules/service-types/ServiceTypesPage';
import { StationsPage } from '../modules/stations/StationsPage';
import { UsersBySystemPage } from '../modules/users/UsersBySystemPage';
import { AlertRulesPage } from '../views/alert-rules/AlertRulesPage';
import { ServiceTechnicianDashboard } from '../views/dashboard/ServiceTechnicianDashboard';
import { ItemSetTemplatePage } from '../views/item-set/ItemSetTemplatePage';
import { QuantityRestrictionsPage } from '../views/quantity-restrictions/QuantityRestrictionsPage';
import { RentersPage } from '../views/renters/RentersPage';
import { StockPage } from '../views/stock/StockPage';

function useMenuItems() {
  const intl = useIntl();

  const menuItems: MenuItem[] = [
    {
      to: '/dashboard',
      label: intl.formatMessage({ id: 'app.dashboard' }),
      component: ServiceTechnicianDashboard,
    },
    {
      to: '/stock',
      label: intl.formatMessage({ id: 'stockPage.self' }),
      component: StockPage,
    },
    {
      to: '/stations',
      label: intl.formatMessage({ id: 'stations.self' }),
      component: StationsPage,
    },
    {
      to: '/service-types',
      label: intl.formatMessage({ id: 'service-types.self' }),
      component: ServiceTypesPage,
    },
    {
      to: '/item-types',
      label: intl.formatMessage({ id: 'item-types.self' }),
      component: ItemTypesPage,
    },
    {
      to: '/items',
      label: intl.formatMessage({ id: 'items.self' }),
      component: ItemsPage,
    },
    {
      to: '/item-set-templates',
      label: intl.formatMessage({ id: 'item-set-template.self' }),
      component: ItemSetTemplatePage,
    },
    {
      to: '/qualifications',
      label: intl.formatMessage({ id: 'qualifications.self' }),
      component: QualificationsPage,
    },
    {
      to: '/quantity-restrictions',
      label: intl.formatMessage({ id: 'quantity-restrictions.self' }),
      component: QuantityRestrictionsPage,
    },
    {
      to: '/contractors',
      label: intl.formatMessage({ id: 'contractors.self' }),
      component: ContractorsPage,
    },
    {
      to: '/renters',
      label: intl.formatMessage({ id: 'renters.self' }),
      component: RentersPage,
    },
    {
      to: '/reports',
      label: intl.formatMessage({ id: 'reports.self' }),
      component: ReportsPage,
    },
    {
      to: '/alert-rules',
      label: intl.formatMessage({ id: 'alert-rules.self' }),
      component: AlertRulesPage,
    },
    {
      to: '/users',
      label: intl.formatMessage({ id: 'users.self' }),
      component: UsersBySystemPage,
    },
  ];

  return { menuItems };
}

export { useMenuItems };
