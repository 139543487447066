import { DataTableRow } from '../components/library/table/DataTable';

export interface StockRow extends DataTableRow {
  id: string;
  itemType: string;
  station: string;
  rented: number | string;
  overdue: number | string;
  total: number;
  requiresService: number;
}

export enum StockRowFieldName {
  ItemType = 'itemType',
  Station = 'station',
  Rented = 'rented',
  Overdue = 'overdue',
  Total = 'total',
  RequiresService = 'requiresService',
}
